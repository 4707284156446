import { createSlice } from '@reduxjs/toolkit'
import { getStorageValue } from '../../../hooks/useLocalStorage'
import { calcTotalPrice } from '../../../utils/calcTotalPrice'

const initialState = {
  totalPrice: getStorageValue('totalPrice', 0),
  cartItems: getStorageValue('cart', []),
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    incrementItem: (state, action) => {
      const findItem = state.cartItems.find((el) => el._id === action.payload._id)

      if (findItem) {
        findItem.count++
      } else {
        state.cartItems.push({
          ...action.payload,
          count: 1,
        })
      }
      state.totalPrice = calcTotalPrice(state.cartItems)
    },
    decrementItem: (state, action) => {
      const findItem = state.cartItems.find((el) => el._id === action.payload._id)

      if (findItem) {
        findItem.count--
        if (findItem.count === 0) {
          state.cartItems = state.cartItems.filter((el) => el._id !== action.payload._id)
        }
      }
      state.totalPrice = calcTotalPrice(state.cartItems)
    },
    removeItem: (state, action) => {
      state.cartItems = state.cartItems.filter((el) => el._id !== action.payload._id)
      state.totalPrice = calcTotalPrice(state.cartItems)
    },
    clearItems: (state) => {
      state.cartItems = []
      state.totalPrice = 0
    },
  },
})

export const { removeItem, clearItems, decrementItem, incrementItem } = cartSlice.actions

export default cartSlice.reducer

export const selectCartItems = (state) => state.cart.cartItems
export const selectTotalPrice = (state) => state.cart.totalPrice
