import React from 'react'
import { Link } from 'react-router-dom'

import { sharesData } from '../../utils/data/data'

import Button from '../../components/ui/button'

import styles from './shares.module.scss'

const SharesPage = () => {
  return (
    <>
      <section className={styles.shares}>
        <div className={styles.content}>
          <div className={styles.title}>
            <h1>Акции</h1>
          </div>
          <div className={styles.items}>
            {sharesData.map((el, i) => (
              <div
                key={i}
                className={styles.item}
              >
                <div className={styles.item_img}>
                  <img
                    src={el.img}
                    alt={el.title}
                  />
                </div>
                <div className={styles.item_title}>
                  <h4>{el.title}</h4>
                </div>
                <div className={styles.item_actions}>
                  <Button
                    type='link'
                    link={el.link}
                    variant='prime'
                    onClick={() => console.log('click')}
                  >
                    Подробнее
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default SharesPage
