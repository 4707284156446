import React, { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'

import FancyBox from '../../components/ui/fancybox'
import Button from '../../components/ui/button'
import Modal from '../../components/ui/modal'
import Socials from '../../components/ui/socials'

import swiperImg1 from '../../assets/img/swiper/1.jpg'
import swiperImg2 from '../../assets/img/swiper/2.jpg'
import swiperImg3 from '../../assets/img/swiper/3.jpg'
import swiperImg4 from '../../assets/img/swiper/4.jpg'
import swiperImg5 from '../../assets/img/swiper/5.jpg'
import swiperImg6 from '../../assets/img/swiper/6.jpg'
import swiperImg7 from '../../assets/img/swiper/7.jpg'

import aboutImg2 from '../../assets/img/about/about2.jpg'

import icon360 from '../../assets/img/360-icon.svg'

import kudagoImg1 from '../../assets/img/kudago-cert.jpg'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import styles from './home.module.scss'

const HomePage = () => {
  const [showModal, setShowModal] = useState(false)
  const tour3dRef = useRef(null)

  const scrollInToElement = (ref) => {
    ref.current.scrollIntoView()
  }

  return (
    <>
      <section className={styles.intro}>
        <Swiper
          className={styles.swiper}
          slidesPerView={4}
          spaceBetween={30}
          centeredSlides={true}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 6500,
            disableOnInteraction: false,
          }}
          speed={1300}
          loop={true}
          loopedSlides={2}
          modules={[Autoplay]}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            991: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1400: {
              slidesPerView: 3.5,
              spaceBetween: 30,
            },
            1600: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
          }}
          // onSlideChange={() => console.log('slide change')}
          // onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide className={styles.slide}>
            <div className={styles.slide_img}>
              <img
                src={swiperImg1}
                alt='Ресторан Сезоны'
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles.slide}>
            <div className={styles.slide_img}>
              <img
                src={swiperImg2}
                alt='Ресторан Сезоны'
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles.slide}>
            <div className={styles.slide_img}>
              <img
                src={swiperImg3}
                alt='Ресторан Сезоны'
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles.slide}>
            <div className={styles.slide_img}>
              <img
                src={swiperImg4}
                alt='Ресторан Сезоны'
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles.slide}>
            <div className={styles.slide_img}>
              <img
                src={swiperImg5}
                alt='Ресторан Сезоны'
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles.slide}>
            <div className={styles.slide_img}>
              <img
                src={swiperImg6}
                alt='Ресторан Сезоны'
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles.slide}>
            <div className={styles.slide_img}>
              <img
                src={swiperImg7}
                alt='Ресторан Сезоны'
              />
            </div>
          </SwiperSlide>
        </Swiper>
        <div className={styles.intro_content}>
          <p>
            Сеть отелей и ресторанов{' '}
            <a
              href='https://friendlyinn.ru'
              target='_blank'
              rel='noreferrer'
            >
              Friendly Inn
            </a>
          </p>
          <h1>
            Ресторан <span>«Сезоны»</span>
          </h1>
          <h2>Блюда европейской, итальянской, русской кухни.</h2>
          <span className={styles.decorator}></span>
          <p>г. Москва, Дегтярный переулок, д. 8, стр 2</p>
          <div className={styles.intro_actions}>
            <Button
              type='button'
              variant='prime'
              onClick={() => setShowModal(true)}
            >
              Забронировать столик
            </Button>
            <Button
              type='link'
              link='tel:+74994041764'
              variant='outlined'
              onClick={() => console.log('click')}
            >
              +7 (499) 404-17-64
            </Button>
          </div>
          <button
            className={styles.trigger_3d}
            onClick={() => scrollInToElement(tour3dRef)}
          >
            <img
              src={icon360}
              alt='3D Tour'
            />
          </button>
        </div>
        {showModal && (
          <Modal onClose={() => setShowModal(false)}>
            <div className={styles.callBack}>
              <p>
                Забронировать столик или ресторан под мероприятие можно в удобном Вам мессенджере
              </p>
              <Socials items={['wa', 'tg']} />
            </div>
          </Modal>
        )}
      </section>
      <section className={styles.about}>
        <div className={styles.about_content}>
          <div className={styles.about_text}>
            <h2>Уютный ресторан в центре Москвы</h2>
            <p>
              Ресторан «Сезоны» расположился в тихом дворике Дегтярного переулка в сердце старой
              Москвы.
            </p>
            <p>
              В ресторане создана атмосфера приватности, где так приятно побеседовать в тишине, не
              перекрикивая музыку: провести время с близкими, встретиться с деловыми партнерами или
              поужинать в приятном уединении. Кроме того, здесь можно тепло отметить праздник или
              любое другое мероприятие без посторонних.
            </p>
            <p>
              Ресторан находится при пятизвездочном бутик-отеле «Regul», а это значит, здесь следуют
              самым высоким стандартам обслуживания. Эстетика вкуснейших блюд и премиальный сервис
              позволят Вам убедиться в этом лично. Здесь подают свежайшие завтраки прямо «из под
              ножа», в обеденное время ждут как ценителей утонченного вкуса, так и тех, кто хочет
              качественно перекусить на бегу, а задержавшиеся после вечеринки гости могут с
              комфортом переночевать в номере отеля.
            </p>
            <p>
              Для теплых дней работает уютная летняя веранда с живым забором и здесь царит приятная
              прохлада сразу возникает ощущение, что находишься где-то за городом.
            </p>
            <p>
              Каждый гость «Сезонов» может почувствовать себя особенным благодаря
              персонализированному подходу. Постоянные клиенты могут быть уверены в том, что в
              ресторане знают и уважают их привычки и вкусы.
            </p>
            <p>
              А близость к метро и собственная бесплатная парковка позволяют комфортно добраться не
              зависимо от средства передвижения.
            </p>
          </div>
          <FancyBox className={styles.about_gallery}>
            {/* <a
              data-fancybox='gallery'
              href={aboutImg1}
              className={styles.about_gallery_item}
            >
              <img
                src={aboutImg1}
                alt='Ресторан Сезоны'
              />
            </a> */}
            <a
              data-fancybox='gallery'
              href={aboutImg2}
              className={styles.about_gallery_item}
            >
              <img
                src={aboutImg2}
                alt='Ресторан Сезоны'
              />
            </a>
            {/* <a
              data-fancybox='gallery'
              href={aboutImg3}
              className={styles.about_gallery_item}
            >
              <img
                src={aboutImg3}
                alt='Ресторан Сезоны'
              />
            </a>
            <a
              data-fancybox='gallery'
              href={aboutImg5}
              className={styles.about_gallery_item}
            >
              <img
                src={aboutImg5}
                alt='Ресторан Сезоны'
              />
            </a> */}
          </FancyBox>
        </div>
      </section>
      <section className={styles.kudago}>
        <div className={styles.kudago_content}>
          <div className={styles.kudago_text}>
            <h2>Лучший гастрономический проект по версии "KUDAGO"</h2>

            <p>
              Мы входим в тройку лучших ресторанов Москвы по версии портала{' '}
              <a
                href='https://kudago.com/msk/place/restoran-sezonyi-34421/'
                target='_blank'
                rel='noopener noreferrer'
              >
                KUDAGO.
              </a>
            </p>
            <p className={styles.notice}>
              Забронировать столик можно по телефону{' '}
              <a href='tel:+74994041764'>+7 (499) 404-17-64</a>
            </p>
          </div>
          <FancyBox className={styles.kudago_img}>
            <a
              data-fancybox='kudago-cert'
              href={kudagoImg1}
              className={styles.kudago_img_item}
            >
              <img
                src={kudagoImg1}
                alt='KudaGo 3th place'
              />
            </a>
          </FancyBox>
        </div>
      </section>
      <section
        ref={tour3dRef}
        className={styles.tour_3d}
      >
        <h2>3D Тур</h2>
        <iframe
          src='https://ru.matterport.host/SdvV9Ua8B8j/?m=SdvV9Ua8B8j&views=0&amp;sr=-2.65,1.02&amp;ss=128'
          frameBorder='0'
          allowFullScreen=''
          allow='xr-spatial-tracking'
          title='3d tour'
        ></iframe>
      </section>
      <section className={styles.video}>
        <video
          controls
          poster='/video-poster.png'
        >
          <source
            src='/the_chef.mp4'
            type='video/mp4'
          />
        </video>
        <h2>Наш Шеф-повар</h2>
      </section>
    </>
  )
}

export default HomePage
