import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  selectCurrentDeliveryItem,
  setDeliveryItem,
} from '../../../store/slices/delivery/deliverySlice'
import { useGetDeliveryItemQuery } from '../../../store/slices/delivery/deliveryApiSlice'

import imgProductPlaceholder from '../../../assets/img/products/no-image.jpg'

import CardButtons from '../card-buttons'

import styles from './full-card.module.scss'

const FullCard = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const currentItem = useSelector(selectCurrentDeliveryItem)

  const { data, isLoading, isSuccess, isError, error } = useGetDeliveryItemQuery(id)

  useEffect(() => {
    if (isSuccess) {
      dispatch(setDeliveryItem({ item: data.data }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  return (
    <div className={styles.card}>
      {isLoading && <p>Загрузка...</p>}
      {isError && <p>При загрузке произошла ошибка</p>}
      {isSuccess && (
        <>
          <div className={styles.img}>
            <img
              src={
                currentItem?.img
                  ? `${process.env.REACT_APP_API_URL}/v1/static/products/${currentItem?.img}`
                  : imgProductPlaceholder
              }
              alt={currentItem?.title}
            />
          </div>
          <div className={styles.info}>
            <div className={styles.title}>
              <h2>{currentItem?.title}</h2>
            </div>
            <div className={styles.description}>
              <p>{currentItem?.description}</p>
            </div>
            <div className={styles.categories}>
              <h4>Категории:</h4>
              <ul className={styles.categories}>
                {currentItem?.categories?.map((cat, i) => (
                  <li key={i}>{cat}</li>
                ))}
              </ul>
            </div>
            <div className={styles.calories}>
              <h4>КБЖУ:</h4>
              <ul className={styles.bju}>
                <li>{currentItem?.info?.calories} ккал</li>
                <li>Белки - {currentItem?.info?.proteins} г.</li>
                <li>Жиры - {currentItem?.info?.fats} г.</li>
                <li>Углеводы - {currentItem?.info?.carbohydrates} г.</li>
              </ul>
            </div>
            <div className={styles.weight}>
              <h4>Вес:</h4>
              <p>{currentItem?.info?.weight} г.</p>
            </div>
            <div className={styles.actions}>
              <CardButtons itemId={currentItem?._id} />
              <p>{currentItem?.price} ₽</p>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default FullCard
