import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  decrementItem,
  incrementItem,
  selectCartItems,
  selectTotalPrice,
} from '../../../store/slices/cart/cartSlice'
import { selectDeliveryItems } from '../../../store/slices/delivery/deliverySlice'

import { useLocalStorage } from '../../../hooks/useLocalStorage'

import styles from './buttons.module.scss'

const CardButtons = ({ itemId }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, setCartItems] = useLocalStorage('cart', [])
  // eslint-disable-next-line no-unused-vars
  const [__, setTotalPrice] = useLocalStorage('totalPrice', 0)

  const totalPrice = useSelector(selectTotalPrice)
  const cartItems = useSelector(selectCartItems)
  const cartItem = cartItems.find((el) => el._id === itemId)
  const deliveryItems = useSelector(selectDeliveryItems)
  const deliveryItem = deliveryItems.find((el) => el._id === itemId)

  const dispatch = useDispatch()

  const incrementCartItem = () => {
    dispatch(incrementItem({ ...deliveryItem }))
  }

  const decrementCartItem = () => {
    dispatch(decrementItem({ ...deliveryItem }))
  }

  useEffect(() => {
    setTotalPrice(totalPrice)
    setCartItems(cartItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems, totalPrice])

  return (
    <div className={styles.buttons}>
      <button
        disabled={cartItem?.count > 0 ? false : true}
        onClick={decrementCartItem}
      >
        -
      </button>
      <span>{cartItem?.count || 0}</span>
      <button onClick={incrementCartItem}>+</button>
    </div>
  )
}

export default CardButtons
