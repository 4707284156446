import React from 'react'
import FancyBox from '../../components/ui/fancybox'

import funnyImg1 from '../../assets/img/funny/1.jpg'
import funnyImg2 from '../../assets/img/funny/2.jpg'
import funnyImg3 from '../../assets/img/funny/3.jpg'

import styles from './funny.module.scss'

const funnyImages = [funnyImg1, funnyImg2, funnyImg3]

const FunnyPage = () => {
  return (
    <>
      <section className={styles.funny}>
        <div className={styles.content}>
          <div className={styles.title}>
            <h1>Развлечения</h1>
          </div>
          <div className={styles.text}>
            <p>
              В нашем ресторане Вы можете воспользоваться разнообразными настольными играми, чтобы
              развлечь себя и своих друзей:
            </p>
            <ul>
              <li>Мафия с масками/ без масок (сутки) – 400/300 руб</li>
              <li>Монополия гарри поттер (сутки) – 400 руб</li>
              <li>Бенга (сутки) – 300 руб</li>
              <li>UNO (сутки) – 300 руб</li>
              <li>Мини иманджинариум (сутки) – 400 руб</li>
            </ul>
            <p>
              Оставить заявку можно по телефону <a href='tel:+74994041764'>+7 (499) 404-17-64</a>
            </p>
          </div>
          <FancyBox className={styles.images}>
            {funnyImages.map((el, i) => (
              <a
                href={el}
                data-fancybox='funny'
                key={i}
                className={styles.images_item}
              >
                <img
                  src={el}
                  alt='Развлечения'
                />
              </a>
            ))}
          </FancyBox>
        </div>
      </section>
    </>
  )
}

export default FunnyPage
