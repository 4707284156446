import React from 'react'
import clsx from 'clsx'

import vkIcon from '../../../assets/img/vk.svg'
import waIcon from '../../../assets/img/wa.svg'
import tgIcon from '../../../assets/img/tg.svg'

import styles from './socials.module.scss'

const socials = [
  {
    name: 'vk',
    link: 'https://vk.com/idfriendlyinn',
    icon: vkIcon,
  },
  {
    name: 'wa',
    link: 'https://wa.me/79254701840',
    icon: waIcon,
  },
  {
    name: 'tg',
    link: 'https://t.me/+79254701840',
    icon: tgIcon,
  },
]

const Socials = ({ items = ['vk'], direction = 'hor' }) => {
  return (
    <div className={clsx(styles.socials, styles[direction])}>
      {items.map((item, index) => {
        const el = socials.find((soc) => soc.name === item)
        if (!el) return ''
        return (
          <a
            key={index}
            href={el.link}
            target='_blank'
            rel='noreferrer'
          >
            <img
              src={el.icon}
              alt={el.name}
            />
          </a>
        )
      })}
    </div>
  )
}

export default Socials
