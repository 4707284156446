import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

import styles from './button.module.scss'

const Button = ({
  className,
  children,
  link = '/',
  type = 'button',
  variant = 'prime',
  onClick,
}) => {
  return type === 'link' ? (
    <Link
      to={link}
      onClick={onClick}
      className={clsx(styles.button, styles.link, styles[variant])}
    >
      {children}
    </Link>
  ) : (
    <button
      onClick={onClick}
      type={type}
      className={clsx(styles.button, styles[variant])}
    >
      {children}
    </button>
  )
}

export default Button
