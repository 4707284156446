import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import clsx from 'clsx'

import { useGetDeliveryItemsQuery } from '../../store/slices/delivery/deliveryApiSlice'
import { selectDeliveryItems, setDeliveryItems } from '../../store/slices/delivery/deliverySlice'
import { selectCartItems, selectTotalPrice } from '../../store/slices/cart/cartSlice'

import ProductCard from '../../components/ui/product-card'

import cartIcon from '../../assets/img/cart.svg'

import styles from './delivery.module.scss'

const DeliveryPage = () => {
  const { data, isLoading, isSuccess, isError, error } = useGetDeliveryItemsQuery()
  const cartItems = useSelector(selectCartItems)
  const totalPrice = useSelector(selectTotalPrice)
  const deliveryItems = useSelector(selectDeliveryItems)

  const dispatch = useDispatch()

  useEffect(() => {
    if (isSuccess) {
      dispatch(setDeliveryItems(data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  return (
    <>
      <section className={styles.delivery}>
        <div className={styles.container}>
          <div className={styles.title}>
            <h1>Доставка из ресторана</h1>
            {cartItems.length > 0 && (
              <Link
                className={styles.title_cart}
                to='/cart'
              >
                <img
                  className={styles.title_cart_icon}
                  src={cartIcon}
                  alt='Корзина'
                />
                <span>{totalPrice}₽</span>
              </Link>
            )}
          </div>
          <div className={styles.filters}>
            <ul className={styles.tabs}>
              <li className={clsx(styles.tabs_item, styles.tabs_item_active)}>Все</li>
              <li className={clsx(styles.tabs_item)}>Закуски</li>
              <li className={clsx(styles.tabs_item)}>Горячее</li>
              <li className={clsx(styles.tabs_item)}>Гарниры</li>
              <li className={clsx(styles.tabs_item)}>Супы</li>
              <li className={clsx(styles.tabs_item)}>Завтраки</li>
              <li className={clsx(styles.tabs_item)}>Салаты</li>
            </ul>
            <div className={styles.sort}>
              <p>Сортировка:</p>
              <span>(А-Я) &#129047;</span>
              {/* <span>(А-Я) &#129045;</span> */}
            </div>
          </div>
          <div className={styles.products}>
            {isLoading && <p>Загрузка...</p>}
            {isError && <p>При загрузке произошла ошибка</p>}
            {isSuccess &&
              deliveryItems.map((item, index) => (
                <ProductCard
                  {...item}
                  key={index}
                />
              ))}
          </div>
        </div>
        {cartItems.length > 0 && (
          <Link
            className={styles.cart}
            to='/cart'
          >
            <span className={styles.cart_badge}>{cartItems.length}</span>
            <img
              className={styles.cart_icon}
              src={cartIcon}
              alt='Корзина'
            />
          </Link>
        )}
      </section>
    </>
  )
}

export default DeliveryPage
