import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  clearItems,
  removeItem,
  selectCartItems,
  selectTotalPrice,
} from '../../store/slices/cart/cartSlice'
import { setDeliveryItems } from '../../store/slices/delivery/deliverySlice'
import { useGetDeliveryItemsQuery } from '../../store/slices/delivery/deliveryApiSlice'

import { useLocalStorage } from '../../hooks/useLocalStorage'

import CartItem from '../../components/ui/cart-card'
import EmptyCart from '../../components/ui/empty-cart'

import styles from './cart.module.scss'
import { Link } from 'react-router-dom'

const CartPage = () => {
  const { data, isLoading, isSuccess, isError, error } = useGetDeliveryItemsQuery()
  // eslint-disable-next-line no-unused-vars
  const [_, setTotalPrice] = useLocalStorage('totalPrice', 0)
  // eslint-disable-next-line no-unused-vars
  const [__, setCart] = useLocalStorage('cart', [])

  const cartItems = useSelector(selectCartItems)
  const totalPrice = useSelector(selectTotalPrice)

  const dispatch = useDispatch()

  const removeCartItem = (id) => {
    dispatch(removeItem({ _id: id }))
  }

  const clearCartItems = () => {
    dispatch(clearItems())
  }

  useEffect(() => {
    setTotalPrice(totalPrice)
    setCart(cartItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems, totalPrice])

  useEffect(() => {
    if (isSuccess) {
      dispatch(setDeliveryItems(data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  return (
    <section className={styles.cart}>
      <div className={styles.content}>
        {cartItems.length > 0 ? (
          <>
            <div className={styles.title}>
              <h1>Корзина ({cartItems.length})</h1>
              <Link
                className={`${styles.action} ${styles.action_back}`}
                to='/delivery'
              >
                К блюдам
              </Link>
              <button
                className={`${styles.action} ${styles.action_clear}`}
                onClick={clearCartItems}
              >
                Очистить корзину
              </button>
            </div>
            <div className={styles.table_head}>
              <div></div>
              <div>Название</div>
              <div>Цена за шт.</div>
              <div>Кол-во</div>
              <div>Итого (₽)</div>
              <div></div>
            </div>
            <div className={styles.items}>
              {cartItems.map((el, i) => {
                return (
                  <CartItem
                    key={i}
                    {...el}
                    remove={removeCartItem}
                  />
                )
              })}
            </div>
            <div className={styles.footer}>
              <p>
                Итого: <span>{totalPrice} ₽</span>
              </p>
              <Link
                to='/checkout'
                className={styles.order}
              >
                <span>К оформлению</span>
                &rarr;
              </Link>
            </div>
          </>
        ) : (
          <EmptyCart />
        )}
      </div>
    </section>
  )
}

export default CartPage
