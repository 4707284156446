import React from 'react'

import Socials from '../../components/ui/socials'

import styles from './contacts.module.scss'

const ContactsPage = () => {
  return (
    <>
      <section className={styles.contacts}>
        <div className={styles.content}>
          <div className={styles.title}>
            <h1>Контакты</h1>
          </div>
          <div className={styles.info}>
            <div className={styles.text}>
              <p>
                Ресторан находится в самом центре Москвы – по адресу Дегтярный переулок, д. 8, стр.
                2
              </p>
              <p>Тверская, Пушкинская и Чеховская – 3 минуты пешком</p>
              <p>Работаем каждый день: с 7:30 до 23:00</p>

              <div className={styles.actions}>
                <p>Забронировать столик или ресторан:</p>
                <Socials items={['vk', 'wa', 'tg']} />
                <a href='tel:+74994041764'>+7 (499) 404-17-64</a>
                <a href='mailto:hotel@friendlyinn.ru'>hotel@friendlyinn.ru</a>
              </div>
            </div>
            <div className={styles.schema}>
              <iframe
                src='https://yandex.ru/map-widget/v1/?um=constructor%3Ab1b64f4d20368d0c50cef4f7ae23894ec54719774361d4c64938ebfb0863405f&amp;source=constructor'
                frameBorder='0'
                title='Схема'
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactsPage
