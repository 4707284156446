import React from 'react'
import { Link } from 'react-router-dom'

import styles from './not-found.module.scss'

const NotFoundPage = () => {
  return (
    <section className={styles.error}>
      <div className={styles.content}>
        <h1>404</h1>
        <h4>Страница не найдена!</h4>
        <p>Возможно она была перемещена или никогда не существовала!</p>
        <Link
          className={styles.goback}
          to='/'
        >
          На главную
        </Link>
      </div>
    </section>
  )
}

export default NotFoundPage
