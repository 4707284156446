import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import { selectCartItems } from '../../../store/slices/cart/cartSlice'

import imgProductPlaceholder from '../../../assets/img/products/no-image.jpg'

import CardButtons from '../card-buttons'

import styles from './card.module.scss'

const ProductCard = ({ _id, title, img, categories, price }) => {
  const location = useLocation()

  return (
    <div className={styles.product}>
      <Link
        to={`/delivery/${_id}`}
        state={{ background: location }}
        className={styles.product_img}
      >
        <img
          src={
            img
              ? `${process.env.REACT_APP_API_URL}/v1/static/products/${img}`
              : imgProductPlaceholder
          }
          alt={title}
        />
      </Link>
      <div className={styles.product_info}>
        <p className={styles.product_cat}>{categories[0]}</p>
        <h4 className={styles.product_title}>{title}</h4>
      </div>
      <div className={styles.product_actions}>
        <span className={styles.product_price}>{price} ₽</span>
        <CardButtons itemId={_id} />
      </div>
    </div>
  )
}

export default ProductCard
