import { useState } from 'react'
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { selectCurrentDeliveryItem, setDeliveryItem } from './store/slices/delivery/deliverySlice'

// Pages
import HomePage from './pages/home'
import GalleryPage from './pages/gallery'
import FunnyPage from './pages/funny'
import SharesPage from './pages/shares'
import SharePage from './pages/share'
import EventsPage from './pages/events'
import DeliveryPage from './pages/delivery'
import DeliveryItemPage from './pages/delivery-item'
import CartPage from './pages/cart'
import LoyaltyPage from './pages/loyalty'
import ContactsPage from './pages/contacts'
import NotFoundPage from './pages/not-found'
import PartyPage from './pages/party'

// Components
import Layout from './components/layout'
import Modal from './components/ui/modal'
import FullCard from './components/ui/full-card'

const App = () => {
  const location = useLocation()
  const locationState = location.state
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const background = locationState && locationState.background

  const deliveryItem = useSelector(selectCurrentDeliveryItem)

  const closeDeliveryItemModal = () => {
    dispatch(setDeliveryItem({ item: null }))
    navigate(-1)
  }

  return (
    <Layout>
      <Routes location={background || location}>
        <Route
          path='/'
          element={
            <Navigate
              to='/home'
              replace
            />
          }
        />
        <Route
          path='/home'
          element={<HomePage />}
        />
        <Route
          path='/gallery'
          element={<GalleryPage />}
        />
        <Route
          path='/cart'
          element={<CartPage />}
        />
        <Route
          path='/funny'
          element={<FunnyPage />}
        />
        <Route
          path='/loyalty'
          element={<LoyaltyPage />}
        />
        <Route path='/delivery'>
          <Route
            index
            element={<DeliveryPage />}
          />
          <Route
            path=':id'
            element={
              <DeliveryItemPage>
                <FullCard />
              </DeliveryItemPage>
            }
          />
        </Route>
        <Route path='/shares'>
          <Route
            index
            element={<SharesPage />}
          />
          <Route
            path=':id'
            element={<SharePage />}
          />
        </Route>
        <Route path='/events'>
          <Route
            index
            element={<EventsPage />}
          />
          {/* <Route
            path=':id'
            element={<SharePage />}
          /> */}
        </Route>
        <Route
          path='/contacts'
          element={<ContactsPage />}
        />
        <Route
          path='*'
          element={<NotFoundPage />}
        />
      </Routes>
      {background && (
        <Routes>
          <Route
            path='/delivery/:id'
            element={
              <Modal onClose={closeDeliveryItemModal}>
                <FullCard />
              </Modal>
            }
          />
        </Routes>
      )}
    </Layout>
  )
}

export default App
