import { apiSlice } from '../../api/apiSlice'

export const deliveryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDeliveryItems: builder.query({
      query: () => `/product`,
      providesTags: (result) => {
        return result
          ? [...result.items.map(({ id }) => ({ type: 'Delivery', id })), 'Delivery']
          : ['Delivery']
      },
    }),
    getDeliveryItem: builder.query({
      query: (id) => `/product/${id}`,
    }),
  }),
})

export const { useGetDeliveryItemsQuery, useGetDeliveryItemQuery } = deliveryApiSlice
