import React from 'react'
import clsx from 'clsx'

import styles from './loyalty.module.scss'

const LoyaltyPage = () => {
  return (
    <>
      <section className={styles.loyalty}>
        <div className={styles.content}>
          <div className={styles.title}>
            <h1>Программа лояльности</h1>
            <p>
              Уважаемы гости, спешим сообщить, что в ресторане «Сезоне» действует система
              лояльности, благодаря которой Вы можете накапливать бонусы.
            </p>
            <p>Бонусами можно оплачивать до 99% стоимости заказа. </p>
          </div>
          <div className={styles.grid}>
            <div className={clsx(styles.grid_item, styles.grid_item_title)}>
              <p>Уровни бонусной программы</p>
            </div>
            <div className={clsx(styles.grid_item, styles.grid_item_title)}>
              <p>Сумма, рубли</p>
            </div>
            <div className={clsx(styles.grid_item, styles.grid_item_title)}>
              <p>Условия начисления бонусов</p>
            </div>
            <div className={clsx(styles.grid_item)}>
              <p>Приветственный бонус</p>
            </div>
            <div className={clsx(styles.grid_item)}>
              <p>0</p>
            </div>
            <div className={clsx(styles.grid_item)}>
              <p>50 рублей</p>
            </div>
            <div className={clsx(styles.grid_item)}>
              <p>Standart</p>
            </div>
            <div className={clsx(styles.grid_item)}>
              <p>до 15 000</p>
            </div>
            <div className={clsx(styles.grid_item)}>
              <p>2% от суммы чека</p>
            </div>
            <div className={clsx(styles.grid_item)}>
              <p>Silver</p>
            </div>
            <div className={clsx(styles.grid_item)}>
              <p>до 30 000</p>
            </div>
            <div className={clsx(styles.grid_item)}>
              <p>4% от суммы чека</p>
            </div>
            <div className={clsx(styles.grid_item)}>
              <p>Gold</p>
            </div>
            <div className={clsx(styles.grid_item)}>
              <p>до 60 000</p>
            </div>
            <div className={clsx(styles.grid_item)}>
              <p>7% от суммы чека</p>
            </div>
            <div className={clsx(styles.grid_item)}>
              <p>Platinum</p>
            </div>
            <div className={clsx(styles.grid_item)}>
              <p>свыше 60 000 </p>
            </div>
            <div className={clsx(styles.grid_item)}>
              <p>10% от суммы чека</p>
            </div>
          </div>
          <div className={styles.registration}>
            <h4>
              Для участия в Программе лояльности необходимо заполнить информацию о себе по ссылке
              ниже
            </h4>
            <a
              href='https://iiko.biz/ru-RU/L/031495'
              target='_blank'
              rel='noopener noreferrer'
            >
              Запонить форму
            </a>
          </div>
        </div>
      </section>
    </>
  )
}

export default LoyaltyPage
