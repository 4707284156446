export const sharesData = [
  {
    title: 'При заказе банкета - номер для молодоженов в подарок',
    subTitle: 'И пусть весь мир подождет.',
    description: [
      'Отметить свадьбу в кругу близких людей, а потом остаться вдвоем и никуда не спешить. Это возможно, если ваше торжество пройдет в изысканных интерьерах ресторана «Сезоны», где достойным подарком для молодоженов будет роскошный номер «Люкс».',
    ],
    optionsTitle: 'В честь Вашего счастливого события мы предоставляем:',
    options: [
      'разработку индивидуального банкетного меню;',
      'номер ЛЮКС в подарок для молодоженов при заказе банкета от 80 000 рублей;',
      'скидку 30% на размещение гостей в номерах бутик-отеля «Regul» 5* или любого отеля сети Friendly Inn;',
      'приятный комплимент от шеф-повара.',
    ],
    conditionsTitle: 'Условия акции:',
    conditions: [
      'Акция действительна при проведении банкета в ресторане "Сезоны"',
      'Скидка на проживание действует в течении 3 суток',
      'Скидка не распространяется на брони через системы онлайн-бронирования',
      'Акция не распространяется на групповое бронирование и туристические агентства',
      'Акция не суммируется c другими акциями и предложениями по проживанию',
      'Акция не суммируется c другими акциями и предложениями ресторана "Сезоны"',
    ],
    img: '/share1.jpg',
    link: '1',
    ctaLink: 'tel:+74994041764',
    ctaText: '+7 (499) 404-17-64',
    ctaTitle: 'Заказать банкет Вы можете по телефону',
    ctaLink2: 'mailto:russiansseasonsrest@gmail.com',
    ctaText2: 'russiansseasonsrest@gmail.com',
    ctaTitle2: 'Или написав на почту',
  },
  {
    title: 'Дарим скидку в день рождения!',
    subTitle: '',
    description: [
      'Если Вы не знаете, где отметить день рождения в Москве, обратите внимание на ресторан «Сезоны" в самом центре столицы – он покорит Ваших гостей изумительной кухней и безупречным сервисом.',
      'Вас будут ждать приятный комплимент от шеф-повара и СКИДКА 20% на все блюда основного меню.',
      'Если же Вы планируете отметить день рождения, юбилей или другое семейное событие уютным камерным банкетом, наш шеф-повар разработает меню специально под Ваше торжество.',
      'В стильном зале ресторана предусмотрена посадка гостей до 20 человек, а значит будь то званный ужин или долгожданная встреча друзей – всё пройдет самым наилучшим образом и без посторонних.',
      'На уютной летней веранде в зависимости от формата торжества возможно проведение мероприятий численностью до 45 человек.',
    ],
    options: [
      'скидка на размещение для именинника - 40%;',
      'скидка на размещение для всех его гостей - 30%.',
    ],
    optionsTitle:
      'Спешим сообщить, что при заказе банкета от 50 000 рублей действует специальное предложение на размещение в бутик-отеле «Regul» 5* или любом другом отеле сети Friendly Inn:',
    conditions: [],
    conditionsTitle: '',
    img: '/share2.jpg',
    link: '2',
    ctaLink: 'tel:+74994041764',
    ctaText: '+7 (499) 404-17-64',
    ctaTitle: 'Забронировать дату Вашего торжества можно по телефону',
  },
  {
    title: 'Четверг – винный день',
    subTitle: '',
    description: [
      'Присоединяйтесь к ценителям хороших вин и роскоши живого общения.',
      'Каждый четверг вас ждет СКИДКА 20% на все винные позиции в меню. Мы с особой тщательностью создавали нашу винную карту и будем рады подобрать для Вас что-то особенное.',
    ],
    options: [],
    optionsTitle: '',
    conditions: [],
    conditionsTitle: '',
    img: '/share3.jpg',
    link: '3',
    ctaLink: 'tel:+74994041764',
    ctaText: '+7 (499) 404-17-64',
    ctaTitle: 'Бронируйте по телефону',
  },
  {
    title: 'СЕТ МЕНЮ от Шефа',
    subTitle: '',
    description: [
      'Приглашаем вкусно и сытно пообедать в центре Москвы в размеренной спокойной обстановке ресторана «Сезоны».',
    ],
    options: [
      'Два премиальных блюда и напиток - 1000 рублей;',
      'Три премиальных блюда и напиток - 1500 рублей.',
    ],
    optionsTitle:
      'Специально для Вас мы создали сбалансированное СЕТ-МЕНЮ, которое действует по будням с 12.00 до 15.00: ',
    conditions: [],
    conditionsTitle: '',
    img: '/share4.jpg',
    link: '4',
    ctaLink: '/set-menu.pdf',
    ctaText: 'Сет-меню.pdf (460kb)',
    ctaTitle:
      'Мы уверены, Вы по достоинству оцените европейскую классику и русские блюда в талантливом исполнении нашего Шефа.',
  },
]
