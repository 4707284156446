import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { sharesData } from '../../utils/data/data'

import styles from './share.module.scss'

const SharePage = () => {
  const [data, setData] = useState({})

  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    setData(sharesData.find((share) => share.link === id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!data) {
      return navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  console.log(data)

  return (
    <>
      <section className={styles.share}>
        <div className={styles.content}>
          <div className={styles.top}>
            <div className={styles.img}>
              <img
                src={data?.img}
                alt={data?.title}
              />
            </div>
            <div className={styles.title}>
              <h1>{data?.title}</h1>
            </div>
          </div>
          {data?.subTitle && <h3>{data?.subTitle}</h3>}
          {data?.description?.length > 0 && (
            <div className={styles.description}>
              {data?.description?.map((p, i) => (
                <p key={i}>{p}</p>
              ))}
            </div>
          )}
          {data?.options?.length > 0 && (
            <div className={styles.options}>
              <h4>{data?.optionsTitle}</h4>
              <ul>
                {data?.options?.map((p, i) => (
                  <li key={i}>{p}</li>
                ))}
              </ul>
            </div>
          )}
          {data?.conditions?.length > 0 && (
            <div className={styles.conditions}>
              <h4>{data?.conditionsTitle}</h4>
              <ul>
                {data?.conditions?.map((p, i) => (
                  <li key={i}>{p}</li>
                ))}
              </ul>
            </div>
          )}
          <div className={styles.actions}>
            <p className={styles.notice}>{data?.ctaTitle}</p>
            <a
              target='_blank'
              rel='noreferrer'
              href={`${data?.ctaLink}`}
            >
              {data?.ctaText}
            </a>
            {data?.ctaLink2 && (
              <>
                <p className={styles.notice}>{data?.ctaTitle2}</p>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href={`${data?.ctaLink2}`}
                >
                  {data?.ctaText2}
                </a>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default SharePage
