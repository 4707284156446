import { configureStore } from '@reduxjs/toolkit'

import { apiSlice } from './api/apiSlice'
import authReducer from './slices/auth/authSlice'
import deliveryReducer from './slices/delivery/deliverySlice'
import cartReducer from './slices/cart/cartSlice'
// import orderReducer from './slices/order/orderSlice'

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    delivery: deliveryReducer,
    cart: cartReducer,
    // order: orderReducer,
  },
  // devTools: false,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([apiSlice.middleware]),
})
