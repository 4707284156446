import { createSlice } from '@reduxjs/toolkit'

const initialState = { user: null, accessToken: null }

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      const { user, accessToken } = action.payload
      state.user = user
      state.accessToken = accessToken
    },
    setAvailebleVideos: (state, action) => {
      const { availebleVideos } = action.payload
      state.user.availebleVideos = availebleVideos
    },
    logOut: (state) => {
      state.user = null
      state.accessToken = null
    },
  },
})

export const { setUser, logOut, setAvailebleVideos } = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state) => state.auth.user
export const selectAvailebleVideos = (state) => state.auth.user.availebleVideos
export const selectAccessToken = (state) => state.auth.accessToken
