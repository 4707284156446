import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  items: [],
  counts: 0,
  currentItem: null,
}

const deliverySlice = createSlice({
  name: 'delivery',
  initialState,
  reducers: {
    setDeliveryItems: (state, action) => {
      state.items = action.payload.items
      state.counts = action.payload.counts
    },
    setDeliveryItem: (state, action) => {
      state.currentItem = action.payload.item
    },
  },
})

export const { setDeliveryItems, setDeliveryItem } = deliverySlice.actions

export default deliverySlice.reducer

export const selectDeliveryItems = (state) => state.delivery.items
export const selectCurrentDeliveryItem = (state) => state.delivery.currentItem
