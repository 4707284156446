import React, { useRef, useEffect } from 'react'
import { Fancybox as NativeFancybox } from '@fancyapps/ui'
import clsx from 'clsx'

import '@fancyapps/ui/dist/fancybox/fancybox.css'

import styles from './fancybox.module.scss'

const FancyBox = ({ delegate = '[data-fancybox]', options = {}, children, className }) => {
  const containerRef = useRef(null)

  useEffect(() => {
    const container = containerRef.current

    NativeFancybox.bind(container, delegate, options)

    return () => {
      NativeFancybox.unbind(container)
      NativeFancybox.close()
    }
  })

  return (
    <div
      className={clsx(styles.fancybox, className)}
      ref={containerRef}
    >
      {children}
    </div>
  )
}

export default FancyBox
