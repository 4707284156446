import React from 'react'
import clsx from 'clsx'

import Fancybox from '../../components/ui/fancybox'

import interiorImg1 from '../../assets/img/interior/1.jpg'
import interiorImg2 from '../../assets/img/interior/2.jpg'
import interiorImg3 from '../../assets/img/interior/3.jpg'
import interiorImg4 from '../../assets/img/interior/4.jpg'

import patioImg1 from '../../assets/img/patio/1.jpg'
import patioImg2 from '../../assets/img/patio/2.jpg'
import patioImg3 from '../../assets/img/patio/3.jpg'
import patioImg4 from '../../assets/img/patio/4.jpg'
import patioImg5 from '../../assets/img/patio/5.jpg'
import patioImg6 from '../../assets/img/patio/6.jpg'
import patioImg7 from '../../assets/img/patio/7.jpg'
import patioImg8 from '../../assets/img/patio/8.jpg'
import patioImg9 from '../../assets/img/patio/9.jpg'
import patioImg10 from '../../assets/img/patio/10.jpg'
import patioImg11 from '../../assets/img/patio/11.jpg'

import styles from './gallery.module.scss'

const interiorImages = [interiorImg1, interiorImg2, interiorImg3, interiorImg4]
const patioImages = [
  patioImg1,
  patioImg2,
  patioImg4,
  patioImg6,
  patioImg7,
  patioImg8,
  patioImg9,
  patioImg11,
]

const GalleryPage = () => {
  return (
    <>
      <section className={styles.gallery}>
        <div className={styles.gallery_content}>
          <div className={styles.title}>
            <h1>Галерея фотографий</h1>
          </div>
          <div className={styles.gallery_block}>
            <h3>Интерьер</h3>
            <Fancybox className={styles.images}>
              {interiorImages.map((el, i) => (
                <a
                  href={el}
                  data-fancybox='interior'
                  key={i}
                  className={styles.images_item}
                >
                  <img
                    src={el}
                    alt='Интерьер'
                  />
                </a>
              ))}
            </Fancybox>
          </div>
          <div className={styles.gallery_block}>
            <h3>Летняя веранда</h3>
            <Fancybox className={styles.images}>
              {patioImages.map((el, i) => (
                <a
                  href={el}
                  data-fancybox='patio'
                  key={i}
                  className={styles.images_item}
                >
                  <img
                    src={el}
                    alt='Летняя веранда'
                  />
                </a>
              ))}
            </Fancybox>
          </div>
        </div>
      </section>
    </>
  )
}

export default GalleryPage
