import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  decrementItem,
  incrementItem,
  selectCartItems,
  selectTotalPrice,
} from '../../../store/slices/cart/cartSlice'
import { useLocalStorage } from '../../../hooks/useLocalStorage'

import imgProductPlaceholder from '../../../assets/img/products/no-image.jpg'
import removeIcon from '../../../assets/img/remove_icon.svg'

import CardButtons from '../card-buttons'

import styles from './card.module.scss'

const CartItem = ({ _id, price, title, img, categories, count, remove }) => {
  const cartItems = useSelector(selectCartItems)
  const totalPrice = useSelector(selectTotalPrice)
  const [_, setTotalPrice] = useLocalStorage('totalPrice', totalPrice)
  const [__, setCart] = useLocalStorage('cart', cartItems)

  useEffect(() => {
    setTotalPrice(totalPrice)
    setCart(cartItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems, totalPrice])

  return (
    <div className={styles.card}>
      <div className={styles.img}>
        <img
          src={
            img
              ? `${process.env.REACT_APP_API_URL}/v1/static/products/${img}`
              : imgProductPlaceholder
          }
          alt={title}
        />
      </div>
      <div className={styles.title}>
        <p>{title}</p>
        <span>{categories[0]}</span>
      </div>
      <div className={styles.price}>
        <p>{price}</p>
      </div>
      <div className={styles.actions}>
        <CardButtons itemId={_id} />
      </div>
      <div className={styles.total}>
        <p>{price * count}</p>
      </div>
      <div className={styles.remove}>
        <button onClick={() => remove(_id)}>
          <img
            src={removeIcon}
            alt=''
          />
        </button>
      </div>
    </div>
  )
}

export default CartItem
